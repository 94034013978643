<template>
    <v-app>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="green" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #FFF">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #FFF">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap class="payment_page">
        <v-flex xs12 sm12 md12 pt-0 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12 md10 align-self-center>
              <p class="itemCaption">
                Payment Success
                <v-icon color="green"> mdi-check-circle-outline </v-icon>
              </p>
              <p class="itemCaption">Thank You For {{ $route.query.eventType }} {{currency}} {{ $route.query.amount }}</p>
              <a
                :href="
                  'https://wayanadapi.leopardtechlabs.in/booking/ticketpdf/?id=' +
                  $route.query.id
                "
                target="blank'"
                style="
                  color: #08903e !important;
                  font-size: 14px !important;
                  text-align: center !important;
                  font-family: poppinsregular;
                "
                >Click here to download the receipt</a
              >
              <p class="itemCaption">
                Your Order ID is
                <span class="itemCaption" style="font-size: 16px !important">
                  {{ $route.query.orderid }}</span
                >
              </p>
              <p>
                <v-btn @click="retpg" dark small color="green">
                  <span>Next</span></v-btn
                >
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  // import axios from "axios";
  export default {
    name: "Success",
    data() {
      return {
        appLoading: false,
        currency: localStorage.getItem("currencySymbol"),
      };
    },
    mounted: function () {
      // this.list();
      this.home();
    },
    methods: {
      retpg() {
        this.$router.push({ path: "/Profile/Orders/" });
      },
      home: function () {
        this.appLoading = true;
        setTimeout(
          function () {
            this.appLoading = false;
            // alert('cool');
            this.$router.push({ path: "/Profile/Orders/" });
          }.bind(this),
          10000
        );
      },
      // list: function () {
      //   axios({
      //     method: "GET",
      //     // url: "/donation/sendmail?id=" + this.$route.query.id,
      //     url: "/donation/sendmail?id=" + this.$route.query.id,
  
      //   });
      //   // .then((response) => {})
      //   // .catch((e) => {});
      // },
    },
  };
  </script>
  <style scoped>
  .payment_page {
    /* background-color: #2aa86638; */
    /* background-image: url("../assets/Images/bg-img2-home.png"); */
    /* background-attachment: fixed; */
    background-size: cover;
  }
  </style>
  